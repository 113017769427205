import {localStorage} from '../../lib/browser-store.js'
import {sqlQuery} from '../../lib/actions.js'
import {applyMeta} from '../../lib/utils.js'
import ev from '../../lib/eb.js'
export default async function mount(){
  if(localStorage.getItem('tune-list-tunes'))
    this.setState({listAvailable: 'true'},false)
  this.notationViewListener = (e) => {
    const {name, switched} = e.detail
    if(name === 'notation-view')
      this.setAttribute('mode', switched === 'on'? 'abc': 'notation')
  }
  
  this.surroundingTunes = () => {
    let tunes = localStorage.getItem('tune-list-tunes')
    const dummy = {prev: '#', next: '#'}
    if(!tunes)
      return dummy 
    if(!this.state.tune)
      return dummy
    tunes = JSON.parse(tunes)
    const l = tunes.length
    let i = 0
    for (i = 0; i < l; i++) {
      const tune = tunes[i];
      if(tune.id === this.state.tune.id)
        break
    }
    const p = tunes[(i + 1 + l) % tunes.length]
    const n = tunes[(i - 1 + l) % tunes.length]
    return {prev: `/tunes/${p.slug}`, next: `/tunes/${n.slug}`}
  }
  ev.addEventListener('switchtoggled', this.notationViewListener)
  const switched = localStorage.getItem('toggleSwitch_notation-view')
  const initMode = switched === 'on'? 'abc': 'notation'
  this.setAttribute('mode', initMode)

  this.fetchTune = async () => {
    const {slug} = this.props
    const query = `SELECT slug, meter, title, composer, id FROM tunes WHERE slug='${slug}'`
    const tune = await sqlQuery({query}).then(r => r.json())
    if(tune.length > 0){
      this.setState({tune: tune[0]})
      if(!window.__SSR__ && window.location.pathname.startsWith('/tunes')){
        applyMeta(tune[0])
      }
    }
  }
  this.fetchTune()
}
