function navigate(href){
  window._MSEVENTSBUS
    .dispatchEvent(new CustomEvent('navigate', {detail: {url: href}}))
}
function anavigate(e){
  e.preventDefault()
  navigate(e.target.href)
}

export {navigate, anavigate}

export default window._MSEVENTSBUS
