import {html} from 'lit-html'
import {useSVG} from '../../lib/utils.js'
import style from './style.css.js'

function Pagination({limit, offset, total, setOffset}){
  const pages = Math.ceil(total / limit) // + ((total % limit) === 0? 0: 1)
  const current = Math.floor(offset / limit) + 1
  const nextOS = current == pages? offset: offset + limit
  const prevOS = current == 1? 0: offset - limit
  //console.log({prevOS, nextOS, limit, offset, total, setOffset});
  return html`
  <div class="pagination">
    <div class="pagination__prev" title="prev" @click=${() => setOffset(prevOS)}>
      ${useSVG('/public/svg/fa/solid/chevron-left.svg', 24)}
    </div>
    <div class="pagination__status" >${current}/${pages}</div>
    <div class="pagination__next" title="next" @click=${() => setOffset(nextOS)}>
      ${useSVG('/public/svg/fa/solid/chevron-right.svg', 24)}
    </div>
  </div>
  `
}
Pagination.style =  style
export default Pagination
