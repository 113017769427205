'use strict';
import {html} from 'lit-html'
import mount from './mount.js'
import '../../lib/events-bus.js'
import eb from '../../lib/eb.js'
//import unmount from './unmount.js'
import style from './style.css.js'
import {createEffect} from '../../lib/comp.js'
import {blindFormat, truncateWords} from '../../lib/utils.js'
import Pagination from '../../elements/pagination/index.js'
const searchEffect = createEffect()

function template({
  limit, fields, rownums, urlfield, urlprefix, show, imgfield, 
  listfields, view, storename, fallback, width, height, desctrunc},
  {rows=[], offset=0, total, searchField, searchString, searchShowing}){
  const setSearchField = (sf) => this.setState({searchField: sf, offset:0})
  const setSearchString = (ss) => this.setState({searchString: ss, offset:0})
  const toggleSearchShowing = () => this.setState({searchShowing: !searchShowing})
  const setOffset = (os) => this.setState({offset: os})
  const showing = show.split(',').map((f) => f.trim())
  const rowfields = listfields.split(',').map((f) => f.trim())
  //console.log({searchField, searchString, offset});
  searchEffect(() => {
    console.log(`Searching collections (${searchField})`, searchString);
    this.searchTable && this.searchTable({search:searchString, field:searchField, limit, offset});
  }, [searchField, searchString, offset])
  //console.log('VIEW => ', view, this.props)
  return html`
    <div class="cl__main">
      ${showing.includes('toggle')? Toggle({view, storename, toggleSearchShowing}): ''}
      ${showing.includes('search')? Search({setSearchField, setSearchString, rowfields, searchShowing, searchString, searchField}): ''}
      ${showing.includes('pagination')? Pagination({offset, total, limit, setOffset}): ''}
      ${view === 'card'
        ? CardView({limit, rowfields, rownums, imgfield, urlfield, urlprefix, rows, offset, 
          total, searchField, searchString, showing, fallback, width, height, desctrunc})
        : TableView({limit, rowfields, rownums, urlfield, urlprefix, rows, offset, total, searchField, searchString, showing})
      }
      ${showing.includes('pagination')? Pagination({offset, total, limit, setOffset}): ''}
    </div>
  `
}

function CardView({limit, rowfields, rownums, urlfield, urlprefix, imgfield, desctrunc,
  rows, offset, total, searchField, searchString, showing, fallback, width, height}){
  const cardStyle = `flex: 0 0 ${width}px;height:${height}px;`
  return html`
<div class="cl__cards">
  ${rows.map((r) => {
    const url = `${urlprefix || ''}${r[urlfield]}`
    const imgStyle = `background-image:url(${r[imgfield] || fallback});`
    return html`
    <div class="cl__card" style="${cardStyle}" @click=${() => eb.dispatchEvent('navigate', {url})}>
      <div class="cl__inner_card"> 
        <div class="cl__bg-image" style="${imgStyle}"></div>
        <div class="cl__card-caption">
          <h5><a href=${url}>${r.title}</a></h5>
          ${r.published? html`<div class="cl__card-date">${new Date(r.published).toDateString()}</div>`: ''}
          ${r.description? html`<div class="cl_card-description">${truncateWords(r.description, desctrunc)}</div>`: ''}
        </div> 
      </div>
    </div>
  `})}
</div>
  `
}

function TableView({limit, rowfields, urlfield, urlprefix, rows, offset, total, showing}){
  return html`
      <table class="cl__table">
        ${TableHeader({rowfields, showing})}
        ${rows.map((row, index) => TableRow({row, rowfields, index, offset, urlprefix, urlfield, showing}))}
      </table>
`
}

function TableHeader({rowfields, showing}){
  return html`
<tr>
  ${showing.includes('numbers') && html`<th>index</th>`}
  ${rowfields.map(f => html`<th>${f}</th>`)}
</tr>
  `
}
function TableRow({row, rowfields, index, offset, urlprefix, urlfield, showing}){
  const url = `${urlprefix || ''}${row[urlfield]}`
  return html`
<tr @click=${() => eb.dispatchEvent('navigate', {url})}>
  ${showing.includes('numbers') && html`<td>${index + 1 + offset}</td>`}
  ${rowfields.map((f,i) =>
    i === 0
    ? html`<td><a href="${url}">${row[f]}</a></td>`
    : html`<td>${blindFormat(row[f])}</td>`
  )}
</tr>
  `
}

function Search({setSearchField, setSearchString, rowfields, searchShowing, searchString, searchField}){
  const _class = searchShowing? 'cl__search showing': 'cl__search'
  return html`
<div class="${_class}">
  <select @change=${(e) => setSearchField(e.target.value)}>
    ${rowfields.map(f => html`<option value="${f}" ?selected=${f === searchField}>${f}</option>`)}
  </select>
  <input
    type="text"
    placeholder="Search"
    @keyup=${(e) => setSearchString(e.target.value)}
    value=${searchString} />
</div>
  `
}

function Toggle({view, storename, toggleSearchShowing}){
  return html`
  <div class="cl__toggle">
    <div>
      <toggle-switch name=${storename} switched=${view === 'card'? 'on': 'off'}>
    </div>
    <div class="cl__toggle-search" @click=${toggleSearchShowing}>
      <use-svg src="/public/svg/fa/solid/magnifying-glass.svg" width="24"></use-svg>
    </div>
  </div>`
}
const props = {
  limit: {
    type: Number,
    value: 50
  },
  offset: {
    type: Number,
    value: 0
  },
  show:{
    type: String,
    value: 'pagination,search,list,toggle,numbers'
  },
  table:{
    type: String,
    value: 'content'
  },
  tag: {
    type: String
  },
  collection: {
    type: String
  },
  type: {
    type: String
  },
  fields: {
    type: String,
    value: 'title,updated_at,image,url,description,published'
  },
  listfields: {
    type: String,
    value: 'title,published'
  },
  urlfield: {
    type: String,
    value: 'url'
  },
  urlprefix: {
    type: String
  },
  imgfield: {
    type: String,
    value: 'image'
  },
  orderbyfield: {
    type: String,
    value: 'published'
  },
  listdirection: {
    type: String,
    value: 'desc'
  },
  mode: {
    type: String,
    value: 'tags'
  },
  fallback: {
    type: String,
    value: "/public/images/fiddle-head-thumb250x150.jpg"
  },
  width: {
    type: Number,
    value: 200
  },
  height: {
    type: Number,
    value: 300
  },
  subfield: {
    type: String,
    value: 'updated_at'
  },
  storename: {
    type: String,
    value: 'collection-lister'
  },
  view: {
    type: String,
    value: 'card'
  },
  desctrunc: {
    type: Number,
    value: 15
  }
}

const component = {
  template,
  mount,
  props,
  persist: ['searchField', 'searchString', 'searchShowing', 'offset'],
  style: style + Pagination.style,
  tagName: 'collection-lister'
}

export default component
