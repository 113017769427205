const sh = 25;
const sw = 50;
export default `
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: ${sw}px;
  height: ${sh}px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: ${sh - 2}px;
  width: ${sh - 2}px;
  left: 0px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: var(--toggle-switch-checked);
  box-shadow: 2px 2px 3px var(--toggle-switch-checked-shadow);
}

input:focus + .slider {
  box-shadow: none;
}

input:checked + .slider:before {
  -webkit-transform: translateX(${sh}px);
  -ms-transform: translateX(${sh}px);
  transform: translateX(${sh}px);
}

/* Rounded sliders */
.slider.round {
  border-radius: ${sh}px;
}

.slider.round:before {
  border-radius: 50%;
}
`
