import cv from '../../style/css-vars.js'
export default `
  .audio-lister__table {
    width: 100%;
    border-collapse: collapse;
  }
  .audio-lister__table tr:hover {
    cursor: pointer;
    background: var(--color-light-grey);
  } 
  .audio-lister__table tr td:nth-child(3) {
    text-align: right;
  }
  .audio-lister__table tr td:nth-child(2) {
    display: none;
  }
  .audio-lister__current-track {
    border: 1px solid var(--color-light-grey);
  }
  .audio-lister__error {
    color: var(--color-error);
  }
  @media (min-width: ${cv.breakpoints.mobile}){
    .audio-lister__table tr td:nth-child(2) {
      display: table-cell;
    }
  }
`
