import {sqlQuery} from '../../lib/actions.js'
import ev from '../../lib/eb.js'
import {localStorage} from '../../lib/browser-store.js'
export default async function mount(){
  this.state = {rows:[], count: 0, offset: 0, searchField: 'title', searchShowing: false}
  this.toggleListener = (e) => {
    const {name} = e.detail
    console.log(e.detail);
    if(name == this.props.storename){
      this.setAttribute('view', this.props.view === 'card'? 'list': 'card')
    }
  }
  ev.addEventListener('switchtoggled', this.toggleListener)
  let switched = localStorage.getItem(`toggleSwitch_${this.props.storename}`)
  if(!switched)
    switched = this.props.view === 'card'? 'on': 'off'
  const initView = switched === 'on'? 'card': 'list'
  this.setAttribute('view', initView)
  this.searchTable = async ({search='', field='title', limit, offset, update=true}) => {
    const {mode, collection, tag, type, table, orderbyfield, listdirection, fields} = this.props
    const {total=50} = this.state
    const colPrefix = getColPrefix(mode)
    const ascDesc = listdirection === 'asc'? 'ASC': 'DESC'
    function template1(fields) {
      return `
        SELECT
          ${fields}
        FROM
          content
        INNER JOIN ${colPrefix}_content_map ON ${colPrefix}_content_map.content_id = content.id
        INNER JOIN tags ON ${colPrefix}_content_map.${colPrefix}_id = ${mode}.id
        WHERE ${mode}.name = '${collection || tag}'
        AND
        content.${field} LIKE '%${search}%'
        ORDER BY ${orderbyfield} ${ascDesc}
        LIMIT ${limit}
        OFFSET ${offset}`
    }

    function template2(_fields) {
      return `
        SELECT ${_fields}
        FROM ${table}
        WHERE ${field} LIKE '%${search}%'
        OFFSET ${offset}
        LIMIT ${limit}
        ORDER BY ${orderbyfield} ${ascDesc};`
    }
    const template = mode === 'table'? template2: template1
    const q1 = template(fields)
    const q2 = template('COUNT(*) AS total')
    const result1 = await sqlQuery({query:q1}).then(r => r.json())
    const result2 = await sqlQuery({query:q2}).then(r => r.json())
//    console.log('Search = ', `"${search}"`, result1)
    if(Array.isArray(result1))
      this.setState({rows: result1, total: Number((result2[0] || {total}).total), offset}, update)
  }
  const recovered = this._getRecoveredObjects()
  if(Object.keys(recovered).length > 0)
    this.setState(recovered, false)
  const {limit, offset, table} = this.props
  await this.searchTable({
    limit,
    search: recovered.searchString,
    offset: recovered.offset || offset,
    field: recovered.searchField,
    table,
    update: false
  })
}

function getColPrefix(mode){
  if(mode === 'collections')
    return 'collection'
  if(mode === 'tags')
    return 'tag'
}
