  export default `
  .pagination {
    display: flex;
    flex-direction: row;
  }
  .pagination svg {
    fill: var(--color-light-grey);
  }
  .pagination:hover svg {
    fill: var(--color-text);
  }
  .pagination__prev {
    text-align: left;
  }
  .pagination__next {
    text-align: right;
  }
  .pagination__prev, .pagination__next {
    flex: 0 0 32px;
  }
  .pagination__prev:hover, .pagination__next:hover {
    cursor: pointer;
  }
  .pagination__status {
    text-align: center;
    flex: 1 1 auto;
  }`
