//import './style/global-style.css'
import navaid from 'navaid'
import {html} from 'lit-html'
import './lib/events-bus.js'
import eb from './lib/eb.js'
import {error as ErrorLayout, tune as TuneLayout} from './layouts'
import * as components from './components/index.js'
import {initComponents} from './lib/utils.js'
import {renderRoute} from './lib/actions.js'
let router
function initRoutes(root){
  const on404 = function(){
    root.innerHTML = ErrorLayout({message: 'Page Not Found', status: 404})
  }
  router = navaid('/', on404);
  router.on404 = on404
  router
    .on('/posts/:slug', params => {
      renderRoute({url:`/posts/${params.slug}`, root, params, router, layout: 'post'})
    })
    .on('/tunes/:slug', params => {
        root.innerHTML = TuneLayout(params)
    })
    .on('/*', params => {
      console.log(params, window.location.pathname);
      renderRoute({url: window.location.pathname, root, params, router})
    })
  router.listen()
  eb.addEventListener('navigate', (e) => {
    const {url} = e.detail
    console.log('navigating to:', url);
    router.route(url)
  })
}

function initApp(appElement){
  // fetcher = initFetcher({
  //   host: window.location.host,
  //   apiBase: 'api'
  // })
  initRoutes(appElement)
  initComponents(components)
}
document.addEventListener('DOMContentLoaded', () => {

  initApp(document.querySelector('#app'))
})
