import cv from '../../style/css-vars.js'
export default `
  .tl__main {
    width: 100%;
  }
  .tl__table {
    width: 100%;
    border-collapse: collapse;
  }
  .tl__table th {
    text-align: left;
    font-weight: bold;
  }
  .tl__table th, .tl__table td {
    padding: 5px;
  }
  .tl__table tr td:first-child {
    color: var(--color-grey);
  }
  .tl__table tr td:nth-child(3),
  .tl__table tr th:nth-child(3) {
    display: none;
  }
  .tl__table tr:hover {
    background: var(--color-light-grey);
    cursor: pointer;
  }

  .tl__search {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    visibility: hidden;
    opacity: 0;
    margin-top: -40px;
    transition: margin-top 0.25s, opacity 0.5s linear;
    z-index: 9;
    position: relative;
  }
  .tl__search.showing {
    visibility: visible;
    margin-top: 0px;
    opacity: 1;
  }
  .tl__search select{
    background: url('/public/svg/fa/solid/angle-down.svg') right center no-repeat;
    background-size: 16px;
  }
  .tl__search :nth-child(1){
    flex: 1 1 100%;
  }

  .tl__search :nth-child(2){
    flex: 1 1 100%;
  }
    .tl__toggle {
    padding: 10px 0px;
    display: flex;
    flex-direction: row;
    justify-content: right;
    position: relative;
    background: var(--color-background);
    z-index: 10;
  }

  .tl__toggle-search svg {
    fill: var(--color-light-grey);
  }
  .tl__toggle-search:hover svg {
    fill: var(--color-text);
  }
  .tl__toggle-search:hover {
    cursor: pointer;
  }
  @media (min-width: ${cv.breakpoints.mobile}) {
      .tl__search {
        display: flex;
        flex-direction: row;
      }
      .tl__search :nth-child(1){
        flex: 0 0 125px;
        padding-bottom: 5px;
      }
      .tl__search :nth-child(2){
        flex: 1 1 auto;
      }
      .tl__table tr td:nth-child(3),
      .tl__table tr th:nth-child(3) {
        display: table-cell;
      }
  }
`
