function makeStore() {
  function fakeStore(){
    let store = {}
    function getItem(k){
      return store[k]
    }
    function setItem(k, v){
      store[k] = v
    }
    return {setItem, getItem}
  }
  
  let storage = window.localStorage || fakeStore()
//  console.log(storage);
//  storage.setItemAsync = async function(k, v) {
//    await null;
//    return storage.setItem(k,v)
//  }
//  storage.getItemAsync = async function(k){
//    await null;
//    return storage.getItem(k)
//  }
  return storage
}

let localStorage = makeStore()

export {localStorage}
