import {sqlQuery, isValid} from '../../lib/actions.js'

export default async function mount(){
  this.setState({
    tracks: [],
    audioCollection: {},
    trackIndex: 0,
    toggling: false,
    errors: []
  }, false)
  this.fetchTracks = async (collectionID) => {
    const query = `
      SELECT
        *
      FROM
        audio_tracks
      LEFT JOIN audio_track_link atl ON atl.audio_track_id = audio_tracks.id
      WHERE atl.audio_collection_id = '${collectionID}'
      ORDER BY track ASC
    `
    const tracks = await sqlQuery({query}).then(r => r.json())
    isValid(tracks, 'Failed to load tracks')
    return tracks
  }
  this.fetchAudioCollection = async (slug) => {
    const query = `SELECT * FROM audio_collections WHERE slug='${slug}'`
    const audioCollection = await sqlQuery({query}).then(r => r.json())
    //console.log({audioCollection})
    isValid(audioCollection, 'Failed to load audio collection')
    return audioCollection[0]
  }
  this.updateAudioCollection = async (slug) => {
    try {
      const audioCollection = await this.fetchAudioCollection(slug)
      const tracks = await this.fetchTracks(audioCollection.id)
      this.setState({tracks, audioCollection, errors: []}, true)
    } catch(e){
      this.setState({errors: [e]}, true)
    }
  }
  const {slug} = this.props
  slug && this.updateAudioCollection(slug)
}
