export default async function mount(){
  this.urlListener = (event) => {
    const {uri} = event
    this.root.querySelectorAll('.navmain__items a')
    .forEach((a) => {
      //console.log(a.pathname, uri);
      if(a.pathname === uri){
        a.classList.add('selected')
      }
      else {
        a.classList.remove('selected')
      }
    });
  }
  this.clickListener = () => {
    if(this.state.toggleState === 'open')
      this.setState({toggleState: 'closed'})
  }
  window.addEventListener('pushstate', this.urlListener);
  this.innerHTML = ''
  this.setState({
    itemsHTML: '',
    logoHTML: '',
    toggleState: 'closed'
  }, false)
  document.body.addEventListener('click', this.clickListener)
  const div = document.createElement('div')
  div.innerHTML = this.__innerHTML
  const itemsEl = div.querySelector('[slot="items"]')
  const logoEl = div.querySelector('[slot="logo"]')
  if(itemsEl)
    this.setState({itemsHTML: itemsEl.innerHTML}, false)
  if(logoEl)
    this.setState({logoHTML: logoEl.innerHTML}, false)
  setTimeout(() => this.urlListener({uri: window.location.pathname}), 50)
}
