'use strict';
import {html} from 'lit-html'
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import mount from './mount.js'
import unmount from './unmount.js'
import style from './style.css.js'
//import {browser} from '../../lib/utils'

function template({config}, {itemsHTML, logoHTML, url, toggleState}){
  //const {items, logo} = config
  const toggleMenu = (e) => {
    const ns = toggleState === 'open'? 'closed': 'open'
    this.setState({toggleState: ns})
    e.stopPropagation();
  }
  const toggleClass = `navmain__toggle ${toggleState}`
  const contentClass = `navmain__content ${toggleState}`
  return html`
    <div class="navmain__main ${toggleState}">
      <div class=${contentClass}>
        <div class="navmain__logo" name="logo">${unsafeHTML(logoHTML)}</div>
        <div class="navmain__items" name="items">${unsafeHTML(itemsHTML)}</div>
      </div>
      <div class="${toggleClass}" @click=${toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
`
}

const props = {}

const component = {
  template,
  mount,
  unmount,
  props,
  style,
  tagName: 'nav-main'
}

export default component
