'use strict';
import {html} from 'lit-html'
import mount from './mount.js'
import unmount from './unmount.js'
import style from './style.css.js'
import {createEffect} from '../../lib/comp.js'
import error from '../../layouts/error.js';
const slugEffect = createEffect() 
//import {browser} from '../../lib/utils'
 
function template({slug}, {audioCollection, tracks, trackIndex, errors}){
  slugEffect(() => {
    this.updateAudioCollection(slug) 
  }, [slug])

  const updateTrackIndex = (i, play) => {
    const ti = i % tracks.length
    if(this.state.toggling)
      return 
    this.setState({trackIndex:ti, toggling: true})
    try {
      if(play)
        setTimeout(() => toggleTrack(ti, this), 250)
    } 
    catch(e){
      console.error(e)
    }
  }
  if(errors.length > 0) {
    return html`
      <div>${errors.map(e => html`<div class="audio-lister__error">${e}</div>`)}</div>
    `
  }
  else {
    return html`
      <div>
        <table class="audio-lister__table">
          ${tracks.map((track,i) => TrackRow({track, trackIndex, i, updateTrackIndex}))} 
        </table>
      </div>
  `
   }
}

function TrackRow({track, trackIndex, i, updateTrackIndex}){
  const {title, track_id, composer, filename} = track
  const url = `/public/audio/${track_id.split('/')[0]}/${filename}` 
  const autoplay = i == trackIndex
  const cls = autoplay? 'audio-lister__current-track': ''
  const id = 'audio-track-' + i
  return html`
<tr class=${cls} onclick=${() => updateTrackIndex(i, true)}>
  <td>${title}</td>
  <td>${composer}</td>
  <td>
    <audio
      id=${id}
      src="${url}" 
      controls 
      preload="metadata"
      onended=${() => updateTrackIndex(i + 1, true)}
      ></audio>
  </td>
</tr>
  `
}

function toggleTrack(i, self){
  const root = self.root
  const tid = 'audio-track-' + i
  root.querySelectorAll('audio').forEach((a) => {
    if(a.id == tid){
      if(a.paused){
        console.log(`playing track ${i}`)
        a.play()
      }
      else {
        console.log(`pausing track ${i}`)
        a.pause()
      }
    }
    else {
      a.pause()
    }
  })
  self.setState({toggling: false}, false)
}
const props = {
  title: {
    type: String,
  },
  slug: {
    type: String,
 },
}

const component = {
  template,
  mount,
  props,
  style,
  mode: 'closed',
  tagName: 'audio-lister'
}

export default component
