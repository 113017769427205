function createEffect(){
  let lastParams = []
  function changed(newParams){
    //console.log({old: lastParams.join(','),new: newParams.join(',')});
    for (var i = 0; i < lastParams.length; i++) {
      if(lastParams[i] !== newParams[i])
        return true
    }
    return false
  }
  return function(cb, params=[]){
    changed(params) && cb()
    lastParams = params
  }
}

export {createEffect}
