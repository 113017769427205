'use strict';
import {html} from 'lit-html'
import mount from './mount.js'
import '../../lib/events-bus.js'
import eb from '../../lib/eb.js'
//import unmount from './unmount.js'
import style from './style.css.js'
import {createEffect} from '../../lib/comp.js'
import Pagination from '../../elements/pagination/index.js'
const searchEffect = createEffect()
const rowfields = ['title', 'composer', 'meter', 'keysig']

function template({limit}, {tunes, offset=0, total, searchField, searchString, searchShowing}){
  const setSearchField = (sf) => this.setState({searchField: sf, offset:0})
  const setSearchString = (ss) => this.setState({searchString: ss, offset:0})
  const toggleSearchShowing = () => this.setState({searchShowing: !searchShowing})
  const setOffset = (os) => this.setState({offset: os})
  searchEffect(() => {
    console.log(`Searching tunes (${searchField})`, searchString);
    this.searchTunes({search:searchString, field:searchField, limit, offset});
  }, [searchField, searchString, offset])
  return html`
    <div class="tl__main">
      ${Toggle({toggleSearchShowing})}
      ${Search({setSearchField, setSearchString, rowfields, searchShowing, searchString, searchField})}
      ${Pagination({tunes, offset, total, limit, setOffset})}
      <table class="tl__table">
        ${TableHeader()}
        ${tunes.map((tune, index) => TableRow({tune, index, offset}))}
      </table>
      ${Pagination({tunes, offset, total, limit, setOffset})}
    </div>
`
}
function TableHeader(){
  return html`
<tr>
  <th>index</th>
  <th>title</th>
  <th>composer</th>
  <th>meter</th>
  <th>key</th>
</tr>
  `
}
function TableRow({tune, index, offset}){
  const {title, keysig, meter, composer, slug} = tune
  const url = `/tunes/${slug}`
  return html`
<tr data-slug="${slug}" @click=${() => eb.dispatchEvent('navigate', {url})}>
  <td>${index + 1 + offset}</td>
  <td><a href="${url}">${title}</a></td>
  <td>${composer}</td>
  <td>${meter}</td>
  <td>${keysig}</td>
</tr>
  `
}

function Search({setSearchField, setSearchString, rowfields, searchShowing, searchString, searchField}){
  //console.log()
  const _class = searchShowing? 'tl__search showing': 'tl__search'
  return html`
<div class="${_class}">
  <select @change=${(e) => setSearchField(e.target.value)}>
    ${rowfields.map(f => html`<option value="${f}" ?selected=${f === searchField}>${f}</option>`)}
  </select>
  <input
    type="text"
    placeholder="Search"
    @keyup=${(e) => setSearchString(e.target.value)}
    value=${searchString} />
</div>
  `
}
function Toggle({toggleSearchShowing}){
  return html`
  <div class="tl__toggle">
    <div class="tl__toggle-search" @click=${toggleSearchShowing}>
      <use-svg src="/public/svg/fa/solid/magnifying-glass.svg" width="24"></use-svg>
    </div>
  </div>`
}

const props = {
  limit: {
    type: Number,
    value: 50
  },
  offset: {
    type: Number,
    value: 0
  },
}

const component = {
  template,
  mount,
  props,
  persist: ['searchString', 'searchField', 'offset', 'limit', 'searchShowing', 'tunes'],
  style: style + Pagination.style,
  tagName: 'tune-list'
}

export default component
