import {html} from 'lit-html'
import mount from './mount.js'
import ev from '../../lib/eb.js'
//import unmount from './unmount.js'
import style from './style.css.js'
//import {browser} from '../../lib/utils'

function template({switched}, {}){
  return html`
<label class="switch">
  <input type="checkbox" @change=${(e) => sendEvent(e, this)} ?checked=${switched === 'on'}/>
  <span class="slider round"></span>
</label>
`
}

function sendEvent(e, parent){
  //console.log('pop');
  const {name} = parent.props
  const checked = e.target.checked
  const switched = checked? 'on': 'off'
  const switchToggled = new CustomEvent('switchtoggled', {
    detail: {
      name,
      switched
    }
  });
  ev.dispatchEvent(switchToggled)
  //parent.dispatchEvent(switchToggled)
  parent.setAttribute('switched', switched)
  parent.setStorageValue(switched)
}

const props = {
  switched: {
    type: String,
    value: 'off'
  },
  name: {
    type: String,
  }
}

const component = {
  template,
  mount,
  props,
  style,
  mode: 'closed',
  tagName: 'toggle-switch'
}

export default component
