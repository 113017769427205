import cv from '../../style/css-vars.js'
export default `
  .cl__main {
    width: 100%;
    z-index: 10;
    position: relative;
  }
  .cl__table {
    width: 100%;
    border-collapse: collapse;
  }
  .cl__table th {
    text-align: left;
    font-weight: bold;
  }
  .cl__table th, .cl__table td {
    padding: 5px;
  }
  .cl__table tr td:first-child {
    color: var(--color-grey);
  }
  .cl__table tr th:first-child,
  .cl__table tr td:first-child {
    display: none;
  }
  .cl__table tr:hover {
    background: var(--color-light-grey);
    cursor: pointer;
  }

  .cl__search {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    visibility: hidden;
    opacity: 0;
    margin-top: -40px;
    transition: margin-top 0.25s, opacity 0.5s linear;
    z-index: 9;
    position: relative;
  }
  .cl__search.showing {
    visibility: visible;
    margin-top: 0px;
    opacity: 1;
  }
  .cl__search select{
    background: url('/public/svg/fa/solid/angle-down.svg') right center no-repeat;
    background-size: 16px;
  }
  .cl__search :nth-child(1){
    flex: 1 1 100%;
  }

  .cl__search :nth-child(2){
    flex: 1 1 100%;
  }
  .cl__toggle {
    padding: 10px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    z-index: 10;
  }

  .cl__toggle-search svg {
    fill: var(--color-light-grey);
  }
  .cl__toggle-search:hover svg {
    fill: var(--color-text);
  }
  .cl__toggle-search:hover {
    cursor: pointer;
  }
  .cl__cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  .cl__card {
    position: relative;
    background: var(--tl-caption-background-color);
    box-shadow: 3px 3px 10px var(--cl-card-shadow-color);
    margin-bottom: 20px;
    margin: 20px;
    transition: transform 0.25s;
    box-sizing: border-box;
  }
  .cl__card:hover {
    cursor: pointer;
    box-shadow: 5px 5px 10px var(--cl-card-shadow-color);
    transform: scale(1.025);
  }
  .cl__card * {
    box-sizing: border-box;
  }
  .cl__inner_card {
    color: var(--tl-caption-color);
    padding: 15px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
  .cl__bg-image {
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 5;
  }
  .cl__card-caption {
    text-align: center;
    position: absolute;
    z-index: 6;
    padding: 10px 15px 15px 15px;
    bottom: 0px;
    left: 0px;
    width: 100%;
    background: var(--tl-caption-background-color);
    color: var(--tl-caption-color);
  }
  .cl__card-date {
    color: #161616;
    font-style: italic;
    font-size: 0.9rem;
  }
  @media (min-width: ${cv.breakpoints.mobile}) {
      .cl__search {
        display: flex;
        flex-direction: row;
      }
      .cl__search :nth-child(1){
        flex: 0 0 125px;
        padding-bottom: 5px;
      }
      .cl__search :nth-child(2){
        flex: 1 1 auto;
      }
      .cl__table tr th:first-child,
      .cl__table tr td:first-child {
        display: table-cell;
      }

  }
`
