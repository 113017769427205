export default class SvgDl extends HTMLElement{
  constructor(){
    super()
    this.state = {mounted: false, width: (this.getAttribute('width') || '32').replace('px', '')}
  }
  connectedCallback(){
    this.setState({mounted: true}, true)
  }
  async updateSrc(src){
    const url = src.startsWith('http')? src: `${window.location.origin}${src}`
    const svg = await fetch(url).then(r => r.text()).catch(e => console.log('failed to load svg', src))
    this.setState({url, svg}, true)
  }
  setState(obj, render){
    this.state = Object.assign({}, this.state, obj)
    render && this.render()
  }
  render(){
    const {svg, width, mounted, url} = this.state
    const el = fromString(svg)
    //console.log({url: (url || '/none').split('/').pop(), width, mounted})
    if(el.tagName === 'svg'){
      if(width)
        el.setAttribute('width', Number(width))
      this.innerHTML = ''
      this.appendChild(el)
    }
  }
  attributeChangedCallback(attr, o, n) {
    const {mounted} = this.state
    if(o != n){
      if(attr === 'src'){
        this.updateSrc(n)
      }
      else if(attr === 'width'){
        this.setState({width: (n || '32').replace('px', '')}, mounted)
      }
    }

  }
  static get observedAttributes(){ return['src', 'width'] }
}
function fromString(svgstr){
  var div = document.createElement('div');
  div.innerHTML = svgstr
  return div.firstChild
}

customElements.define('use-svg', SvgDl)
