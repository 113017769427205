export default function({body, title}){
  return `
<div class="page">
  <div class="ms-header-published">
    <h1>${title}</h1>
    <div></div>
  </div>
  ${body}
</div>
  `
}
