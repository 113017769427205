export default function({message, status}){
  return `
<div class="page">
  <h1>${status} Error</h1>
  <div>
    ${message}
  </div>
</div>
  `
}
