class EventBus{
    constructor(){
        this.bus = document.createElement('div');
    }
    addEventListener(event, callback){
        this.bus.addEventListener(event, callback);
    }
    removeEventListener(event, callback){
        this.bus.removeEventListener(event, callback);
    }
    dispatchEvent(event, detail = {}){
        this.bus.dispatchEvent(event instanceof CustomEvent? event: new CustomEvent(event, { detail }));
    }
}
if (typeof window !== 'undefined')
  window._MSEVENTSBUS = new EventBus()
export default EventBus
