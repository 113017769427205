export default `
  .nv_container {
    margin: 20px 0px;
    clear: both;
    width: 100%;
  }
  .nv_container img {
    width: 100%;
  }
  .nv_controls {
    display: flex;
    justify-content: left;
  }
  .nv_controls audio {
    height: 32px;
    transform: translateY(-3px);
  }
  .nv_options {
    display: flex;
    flex-direction: row;
    // padding: 5px;
  }
  .nv_options a {
    padding: 0px 5px;
    color: var(--color-grey);
    text-decoration: none;
  }
  .nv_options a:hover {
    cursor: pointer;
    filter: brightness(0.5);
    text-decoration: underline;
  }
  .nv-navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    line-height: 0.5;
    margin-bottom: 10px;
  }
  .nv-navigation a {
    color: var(--color-grey);   
    text-decoration: none;
    font-size: 2rem;
  }
  .nv-navigation a:visited {
    color: var(--color-grey); 
  }
  .nv-navigation a:hover {
    cursor: pointer;
    color: var(--color-text); 
  }

`
