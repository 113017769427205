//import fetch from 'isomorphic-fetch'
'use strict';
import {html} from 'lit-html'
import {urlJoin} from '../../lib/utils.js'

function template({file, trim}, {contents}){
  const c = trim? contents.trim(): contents
  return html`<div><pre>${c}</pre></div>`
}

async function mount(){
  const {file} = this.props
  const url = urlJoin(window.location.origin, file)
  const contents = await fetch(url).then(r => r.text())
  this.setState({contents}, false)
}

const props = {
  file: {
    type: String
  },
  trim: {
    type: Boolean,
    value: true 
  }
}

const component = {
  template,
  mount,
  props,
  tagName: 'include-file'
}

export default component
