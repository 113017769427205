import {html} from 'lit-html'
import mount from './mount.js'
import unmount from './unmount.js'
import {anavigate} from '../../lib/eb.js'
//import('../midi-player.js')
//import fetch from 'isomorphic-fetch'
//import {browser} from '../../lib/utils'
import style from './style.css.js'
function template({mode, slug, show}, {tune={}, listAvailable}){
  const showing = show.split(',')
  //console.log({mode});
  const switched = mode == 'notation'? 'off': 'on'
  const stub = `/public/tunes/${slug}/${slug}`
  return html`
    <div class="nv_container">
      ${showing.includes('title')? html`<h1 class="nv_title">${tune.title}</h1>`: ''}
      ${listAvailable? Navigation(this.surroundingTunes()):''}
      ${showing.includes('controls')? Controls(switched, showing, stub): ''}
      ${mode === 'notation'? NotationMode(stub): AbcMode(stub)}
    </div>
  `
}

function AbcMode(stub){
  const file = `${stub}.abc`
  return html`
    <div>
      <include-file file="${file}"></include-file>
    </div>
  `
}

function NotationMode(stub){
  const src = `${stub}.png`
  return html`
    <div>
      <img src="${src}"/>
    </div>
  `
}

function Navigation({prev, next}){
  return html`
  <div class="nv-navigation">
    <a href="${prev}" @click=${anavigate}>&larr;</a>
    <a href="${next}" @click=${anavigate}>&rarr;</a>
  </div>
  ` 
}

function Controls(switched, showing, stub){
  return html`
<div class="nv_controls">
  <div>
    <toggle-switch switched="${switched}" name="notation-view"></toggle-switch>
  </div>
  ${showing.includes('options')? Options(stub): ''}
</div>
  `
}

function Options(stub){
  const stubs = {
    abc: `${stub}.abc`,
    pdf: `${stub}.pdf`,
    html: `${stub}.html`,
    midi: `${stub}.midi`,
    mp3: `${stub}.mp3`
  }
  return html`
  <div class="nv_options">
    <a href="${stubs.abc}" download>abc</a>
    <a href="${stubs.pdf}" download>pdf</a>
    <a href="${stubs.html}" target="_blank">html</a>
    <a href="${stubs.midi}" target="_blank">midi</a>
  </div>
  <audio
    controls
    preload="metadata"
    src="${stubs.mp3}">
  </audio>
  `
}

const props = {
  mode: {
    type: String,
    value: 'notation'
  },
  slug: {
    type: String
  },
  show: {
    value: 'notation,controls,options',
    type: String
  }
}

const component = {
  template,
  mount,
  unmount,
  style,
  props,
  tagName: 'notation-view',
  mode: 'closed'
}

export default component
//customElements.define("my-component", MyComponent);
