import {c} from '../components/base.js'
import * as layouts from '../layouts/index.js'
import {html} from 'lit-html'
const TRUE_VALUES = {true: true, "true": true}
function slugify(str){
  return str.toLowerCase()
    .replace(/_+|-+| +/g, '-')
    .replace(/"|'+|\?+|\!+/g, '')
}

function urlJoin(){
  return Array.from(arguments)
    .map(s => s.replace(/([\/]+$)|(^[\/]+)/g, ''))
    .join('/')
}

function initComponents(components){
  for (var cname in components){
    var comp = components[cname]
    console.log(`defining component ${comp.tagName}`);
    customElements.define(comp.tagName, c(comp))
  }
}

function q(s, e){
  const d = e || document
  if(d.querySelector)
    return d.querySelector(s)
}

function applyMeta({title, description, image, keywords}){
  document.title = title
  q('meta[name="description"]').content = description
  q('meta[name="image"]').content = image
  q('meta[name="keywords"]').content = keywords
}

function browser() {
  return (typeof window !== 'undefined')
}
function getHost(){
  return isServer()? process.env.LOCAL_HOST: window.location.host;
}
function transformProp(type, value){
  return type == Boolean
        ? !!TRUE_VALUES[value]
        : type == Number
        ? Number(value)
        : type == Array || type == Object
        ? JSON.parse(value)
        : type == Date
        ? new Date(value)
        : value;
}
function stringify(obj){
  return Array.isArray(obj) || typeof obj === 'object'
    ? JSON.stringify(obj)
    : (obj).toString()
}
function unstringify(ob){
  if(typeof ob != 'string')
    return ob
  const trimmed = ob.trim()
  return /^(\{|\[).*/.test(trimmed)
    ? JSON.parse(trimmed)
    : /^[\-\+]?[0-9\.]+$/.test(trimmed)
    ? Number(trimmed)
    : /^(true|false)$/.test(trimmed)
    ? trimmed === 'true'
    : String(ob)
}
function blindFormat(value){
  var m = /(\d{1,2})[\/\-](\d{1,2})[\/\-](\d{4})(.*)/.exec(value)
  if(m){
    const nd = `${m[3]}/${m[2]}/${m[1]}${m[4]}`
    return (new Date(nd)).toDateString()
  }
  m = /(\d{4}\-\d{1,2}\-\d{1,2})|(\d{2}:\d{2}:\d{2} +GMT.*)/.exec(value)
  if(m){
    return (new Date(value)).toDateString()
  }
  return value
}
function addStyle(str, id){
  let el = q(`#${id}`, document.head)
  if(!el){
    el = document.createElement('style')
    el.id = id
    el.innerHTML = str
    document.head && document.head.appendChild(el)
  }
}
function useSVG(src, width=32){
  return html`<use-svg src="${src}" width="${width}"></use-svg>`
}
function truncateWords(inputString, numWords) {
  const words = inputString.split(' ');
  if (numWords >= words.length) {
    return inputString;
  }
  const truncatedWords = words.slice(0, numWords);
  return truncatedWords.join(' ') + '...'
}
export {
  urlJoin, stringify, unstringify, useSVG, initComponents, q, 
  blindFormat, slugify, applyMeta, browser, getHost,
  transformProp, addStyle, truncateWords}
