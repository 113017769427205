import {transformProp, addStyle, stringify, unstringify} from '../lib/utils.js'
import {localStorage} from '../lib/browser-store.js'
//import {hooked} from 'uhooks'
import {html, render} from 'lit-html'
function c(component){
  const {style, mode, props, template, tagName, mount, unmount, persist=[]} = component
  const shadow = typeof mode !== 'undefined'
  const propKeys = Object.keys(props)
  class MSElement extends HTMLElement {
    constructor(){
      super()
      this.root = mode != undefined? this.attachShadow({ mode }): this;
      this._flags = {mounted: false, recover: true}
      this.__innerHTML = this.innerHTML
      this.state = {}
      this.props = {}
      if(this.dataset.ssr){
        this.innerHTML = ''
        this.removeAttribute('data-ssr')
      }
      var k, p
      for (var i = 0; i < propKeys.length; i++) {
        k = propKeys[i]
        p = props[k]
        if('value' in p)
          this.props[k] = transformProp(p.type, p.value)
      }
      for (var i = 0; i < this.attributes.length; i++) {
          var a = this.attributes[i];
          this._setProp(a.nodeName, a.nodeValue, false)
      }
      this.template = template.bind(this)
      if(!shadow && style)
        addStyle(style, `${tagName}-style`)
      if(mount)
        this._mount = mount.bind(this)
      if(unmount)
        this._unmount = unmount.bind(this)
    }
    async connectedCallback(){
      //console.log(this.root.innerHTML);
      //this.root.innerHTML = ''
      const {recover} = this._flags
      if(persist && recover)
        this._recoverState(false)
      this._mount && await this._mount()
      this.render()
      this.setFlags({mounted: true})
    }
    disconnectedCallback(){
      this._unmount && this._unmount()
    }
    attributeChangedCallback(attr, oldValue, value) {
      const {mounted} = this._flags
      if(oldValue != value)
        this._setProp(attr, value, mounted)
    }
    setState(obj, update=true){
      this.state = Object.assign({}, this.state, obj)
      update && this.render()
      if(persist)
        this._persistState()
    }
    setFlags(obj){
      this._flags = Object.assign({}, this._flags, obj)
    }
    _persistState(){
      const storename = this._storeName()
      for (var i = 0; i < persist.length; i++) {
        const k = persist[i]
        if(k in this.state){
          localStorage.setItem(`${storename}-${k}`, stringify(this.state[k]))
        }
      }
    }
    _recoverState(update=false){
      const recovered = this._getRecoveredObjects()
      this.setState(recovered, update)
    }
    _getRecoveredObjects(){
      const storename = this._storeName()
      var recovered = {}
      for (var i = 0; i < persist.length; i++) {
        const k = persist[i]
        const it = localStorage.getItem(`${storename}-${k}`)
        if(it)
          recovered[k] = unstringify(it)
      }
      //console.log(recovered);
      return recovered
    }
    _storeName(){
      const {storename} = this.props
      return storename? `${storename}_${tagName}`: tagName
    }
    render = () => {
      if(shadow){
        render(html`
          <style>${style}</style>
          ${this.template(this.props, this.state)}
        `, this.root)
      }
      else {
        render(this.template(this.props, this.state), this.root)
      }
    }
    _setProp(attr, v, update=true){
      if(propKeys.includes(attr)){
        const {type, value} = props[attr]
        this.props[attr] = transformProp(type || String, v)
      }
      else {
        this.props[attr] = v
      }
      update && this.render()
    }
    static get observedAttributes() {
      return propKeys.concat(super.observedAttributes || []);
    }
  }
  return MSElement
}
export {c}
