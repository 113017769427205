import {sqlQuery} from '../../lib/actions.js'
//import {localStorage} from '../../browser-storage.js'
export default async function mount(){
  this.state = Object.assign({tunes:[], count: 0, offset: 0, searchField: 'title', total: 0}, this.state)
  this.searchTunes = async ({search='', field, limit, offset}) => {
    const template = (fields) => `
      SELECT ${fields}
      FROM tunes
      WHERE ${field} LIKE '%${search}%'
      LIMIT ${limit}
      OFFSET ${offset}`
    const q1 = template(`title, meter, composer, keysig, id, slug`)
    const q2 = template(`COUNT(*) as total`)
    var tunes = await sqlQuery({query: q1}).then(res => res.json())
    this.setState({tunes, offset}, true)
    const count = await sqlQuery({query: q2}).then(res => res.json())
    if(count.length > 0)
      this.setState({total: count[0].total}, true)
    //localStorage.setItem('current-tunes', tunes)
  }
  const {limit, offset} = this.props
  const {searchString} = this.state
  this.searchTunes({limit, offset, field: 'title', search: searchString })
}
