import {applyMeta} from './utils.js'
import * as layouts from '../layouts/index.js'
const ORIGIN = window.location.origin
const API_PREFIX = '/public/api'
const SQL_PREFIX = '/public/sql'

async function sqlQuery({query}){
  return await fetch(`${ORIGIN}${SQL_PREFIX}`, {
    method: 'POST',
    headers: {'Content-Type': 'text/plain'},
    body: query
  });
}

async function renderRoute({url, root, params, router, layout}){
  const query = `SELECT * FROM content WHERE url='${url}';`
  console.log('renderRoute:', query);
  return await sqlQuery({query})
      .then(res => res.json())
      .then(data => {
        //console.log(data);
        if(!data || data.length == 0) throw Error('no data available')
        const item = data[0]
        applyMeta(item)
        var lo = item.layout || layout? layouts[item.layout || layout]: layouts.page
        root.innerHTML = lo(item)
         document.body.className = document.body.className.split(' ').filter((c) => !/page-/.test(c)).join(' ')
         document.body.classList.add(`page-${item.slug}`) 
      }).catch(err => {
        console.error(err);
        router.on404({message: `page "${url}" not found`, status: '404'})
      })
}
function isValid(response, msg){
  //console.log({response})
  if(!Array.isArray(response))
    throw new Error(msg)
  if(response.length == 0)
    throw new Error(msg)
}
export {sqlQuery, isValid, renderRoute}
