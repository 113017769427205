const burgerWidth = 24;
const burgerHeight = burgerWidth * 0.7500
const burgerLineHeight = burgerWidth * 0.1500
export default `
.navmain__main {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 99;
  width: 100%;
  height: ${burgerHeight}px;
}
.navmain__content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background: var(--color-dark);
}
.navmain__logo {
  display: flex;
  flex-direction: column;
  justify-content: left;
  color: var(--color-white);
  position: relative;
  text-align: center;
}
.navmain__logo > a {
  font-weight: 600;
  border-top: 1px solid var(--navmain-item-border-color);
  text-align: center;
  padding: 5px;
  width: 100%;
}
.navmain__items {
  display: flex;
  flex-direction: column;
  justify-content: right;
  color: var(--color-white);
  position: relative;
}
.navmain__items > a {
  font-weight: 600;
  border-top: 1px solid var(--navmain-item-border-color);
  text-align: center;
  padding: 5px;
  width: 100%;
}
.navmain__toggle {
  width: ${burgerWidth}px;
  height: ${burgerHeight}px;
  position: absolute;
  top: 0px;
  left: 0px;
  margin-left: 10px;
  margin-top: 10px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  z-index: 10;
}

.navmain__toggle span {
  display: block;
  position: absolute;
  height: ${burgerLineHeight}px;
  width: 100%;
  background: var(--color-dark);
  border-radius: ${burgerLineHeight}px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}
.navmain__toggle span:nth-child(1) {
  top: 0px;
}

.navmain__toggle span:nth-child(2),.navmain__toggle span:nth-child(3) {
  top: ${burgerWidth * 0.3}px;
}

.navmain__toggle span:nth-child(4) {
  top: ${burgerWidth * 0.6}px;
}

.navmain__toggle.open span:nth-child(1) {
  top: ${burgerWidth * 0.3}px;
  width: 0%;
  left: 50%;
}

.navmain__toggle.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.navmain__toggle.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.navmain__toggle.open span:nth-child(4) {
  top: ${burgerWidth * 0.3}px;
  width: 0%;
  left: 50%;
}
.nav-main.open {
  height: auto;
}
.navmain__toggle.open span {
  background: var(--color-white);
}

.navmain__content {
  transform: translateY(-500px);
  transition: transform 0.25s;
}

.navmain__content.open {
  transform: translateY(0px);
}

@media (min-width: 768px) {
  .navmain__content {
    flex-direction: row;
    transform: translateY(0px);
    background: initial;
  }
  .navmain__content a{
    border: none;
  }
  .navmain__logo {
    flex-direction: row;
    justify-content: left;
  }
  .navmain__items {
    display: flex;
    flex-direction: row;
    justify-content: right;
  }
  .navmain__items > a {
    padding: 10px;
    color: var(--color-dark);
    border: none;
  }
  .navmain__logo > a {
    color: var(--color-dark);
  }
  .navmain__items > a.selected {
    text-decoration: underline;
  }
  .navmain__toggle {
    display: none;
  }
}
`
