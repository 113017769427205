import {localStorage} from '../../lib/browser-store.js'
export default async function mount(){
  this.getStorageName = () => {
    const {name} = this.props
    return `toggleSwitch_${name}`
  }
  this.setStorageValue = (v) => {
    const sName = this.getStorageName()
    localStorage.setItem(sName, v)
  }
 }
